<template>
  <div
    class="flex justify-content-between gap-2 mt-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('title')"
      :input="projectData.Title">
      <InputText
        class="w-full"
        id="Title"
        v-model="projectData.Title"
        :placeholder="this.$t('placeholder.title')"
        :class="v$.projectData.Title.$error ? 'p-invalid' : ''"/>
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('responsible')"
      :input="projectData.Responsible ? projectData.Responsible.Name : ''">
      <Dropdown
        class="w-full"
        v-model="projectData.Responsible"
        :options="responsibleOptions"
        optionLabel="Name"
        filter
        autoFilterFocus
        :placeholder="this.$t('placeholder.responsible')" :class="v$.projectData.Responsible.$error ? 'p-invalid' : ''"/>
    </CustomLabel>
  </div>
  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('company')"
      :input="projectData.Company ? projectData.Company.Name : ''">
      <Dropdown
        class="w-full"
        v-model="projectData.Company"
        :options="companyOptions"
        :optionLabel="getCompanyOptionLabel"
        filter
        autoFilterFocus
        :placeholder="this.$t('placeholder.company')"
        :class="v$.projectData.Company.$error ? 'p-invalid' : ''"
        @update:modelValue="setProjectCompany"
        @filter="searchForCompanies"/>
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      label="Phase"
      :input="projectData.Phase">
      <Dropdown 
        class="w-full"
        v-model="projectData.Phase"
        :options="phaseOptions"
        optionLabel="Name"
        placeholder="Phase"
        :class="v$.projectData.Phase.$error ? 'p-invalid' : ''"/>
    </CustomLabel>
  </div>

  <div
    v-if="projectData.Company"
    class="mb-2">
    <div
      class="flex justify-content-between gap-2 mb-2">
      <CustomLabel
        class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
        :label="$t('location')"
        :input="projectData.Location ? projectData.Location.LocationName : ''">
        <Dropdown 
          class="w-full"
          v-model="projectData.Location"
          :options="locationOptions"
          optionLabel="LocationName"
          :placeholder="$t('placeholder.location')" />
      </CustomLabel>

      <CustomLabel
        class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
        :label="$t('supplier')"
        :input="projectSupplier">
        <Dropdown
          class="w-full"
          v-model="projectSupplier"
          :options="supplierOptions"
          optionLabel="Name"
          @update:modelValue="setProjectSupplier"
          :placeholder="$t('placeholder.supplier')" />
      </CustomLabel>
    </div>

    <ContactSection
      :company-id="projectData.Company.Id"
      :contact-props="projectData.Contact"
      :contact-options="contactOptions"
      :is-dropdown="true"
      @contact-changed="setContact"/>
  </div>

  <div
    class="flex justify-content-between gap-2 mb-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('members')"
      :input="projectData.Members[0] ? projectData.Members[0].Name : ''">
      <MultiSelect
        class="w-full truncate"
        v-model="projectData.Members"
        :options="membersOptions"
        optionLabel="Name"
        filter
        :placeholder="this.$t('placeholder.selectMembers')"
        autoFilterFocus />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('budget')"
      :input="projectData.Budget">
      <InputNumber
        class="w-full truncate"
        v-model="projectData.Budget"
        :placeholder="this.$t('placeholder.budget')" />
    </CustomLabel>
  </div>


  <div
    class="pt-2">
    <CustomLabel
      :label="this.$t('description')"
      :input="projectData.Description">
      <Editor 
        class="w-full"
        editorStyle="height: 320px"
        v-model="projectData.Description"
        id="Description"
        :placeholder="this.$t('placeholder.description')"> 
        <template v-slot:toolbar>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-list" value="check"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-clean"></button>
          </span>
        </template>
      </Editor>
    </CustomLabel>
  </div>

  <div
    class="flex align-items-center gap-1 mt-2">
    <InputSwitch v-model="hasEquipment" />
    <span
      v-if="hasEquipment">
      {{this.$t('hasEquipments')}}
    </span>
    <span
      v-else>
      {{this.$t('hasNoEquipments')}}
    </span>
  </div>

  <div
    v-if="hasEquipment"
    class="flex justify-content-between gap-2 mb-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('equipments')"
      :input="projectData.Equipment[0] ? projectData.Equipment[0].Name : ''">
      <MultiSelect
        class="w-full"
        v-model="projectData.Equipment"
        :options="equipmentOptions"
        optionLabel="Name"
        filter
        :placeholder="this.$t('placeholder.equipments')"
        autoFilterFocus/>
    </CustomLabel>
  </div>

  <div class="mt-4 mb-4 border-bottom-1"/>

  <div
    class="mb-6">
    <div>
      {{ $t('specialFields') }}
    </div>
    <div>
      <div class="w-full">
        <CustomLabel
          class="pt-2 w-full"
          :label="this.$t('classification')"
          :input="selectedTagContainer">
          <Dropdown
            class="w-full"
            v-model="selectedTagContainer"
            :options="tagContainers"
            optionLabel="ContainerName"
            :placeholder="this.$t('classification')"
            :class="v$.selectedTagContainer.$error ? 'p-invalid' : ''" />
        </CustomLabel>
      </div>

      <div
        v-for="(customList) of customLists"
        class="flex justify-content-between gap-2"
        :key="customList.Id">
        <div
          v-if="customList && customList.FieldParams.IsInputField"
          class="w-full" >
          <CustomLabel
            class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
            :label="getCustomListLabel(customList)"
            :input="projectData.ProjectTags[customList.Id]">
            <InputText
              class="w-full"
              v-model="projectData.ProjectTags[customList.Id]"
              :class="!customList.isOptional ? v$.projectData.ProjectTags[customList.Id].$error ? 'p-invalid' : '' : ''"
              :placeholder="getCustomListLabel(customList)"/>
          </CustomLabel>
        </div>
        <div
          v-else-if="customList && customList.HasMultipleOptions"
          class="w-full">
          <CustomLabel
            class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
            :label="getCustomListLabel(customList)"
            :input="projectData.ProjectTags[customList.Id]">
            <MultiSelect
              class="w-full"
              :options="customList.TagNames"
              filter
              autoFilterFocus
              v-model="projectData.ProjectTags[customList.Id]"
              :class="!customList.isOptional ? v$.projectData.ProjectTags[customList.Id].$error ? 'p-invalid' : '' : ''"
              :placeholder="getCustomListLabel(customList)" />
          </CustomLabel>
        </div>
        <div
          v-else-if="customList"
          class="w-full">
          <CustomLabel
            class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
            :label="getCustomListLabel(customList)"
            :input="projectData.ProjectTags[customList.Id]">
            <Dropdown
              class="w-full"
              v-model="projectData.ProjectTags[customList.Id]"
              :options="customList.TagNames"
              :class="!customList.isOptional ? v$.projectData.ProjectTags[customList.Id].$error ? 'p-invalid' : '' : ''"
              :placeholder="getCustomListLabel(customList)" />
          </CustomLabel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor"
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ContactSection from "@/components/global-components/contact-section/ContactSection";

export default {
  name: "AddProject",
  components:{
    ContactSection,
    CustomLabel, InputText, Dropdown, Editor, MultiSelect, InputSwitch, InputNumber},
  mixins:[Uuidv4Generator],
  emits: ['closePopup'],
  data(){
    return{
      projectData: {
        Budget:null,
        Changelogs:[],
        Closed:0,
        Company: null,
        CompanyId:"-",
        Contact:{Id:"",Name:"",Email:"",Phone:"",Landline:""},
        ContactId:"-",
        Correspondence:[],
        Created: 0,
        Creator: this.$store.getters.shortUsername,
        Description: "",
        Domain:this.$store.getters.domainName,
        Equipment: [],
        Files:[],
        Id: this.createUuidv4(),
        LastEdited:0,
        LeadConversionDate:0,
        LeadTagContainer:{},
        LeadTags:{},
        Location:{},
        Members:[],
        Phase:{Number:10,Name:'Projektanfrage'},
        Prio:"-",
        ProjectTagContainer:{},
        ProjectTags:{},
        ProjectType: '',
        Reminders: [],
        Responsible:  {},
        ResponsibleId:"-",
        Result: "",
        Status: "10",
        Supplier: '',
        SupplierId: '',
        Title: "",
        Type:"Project",
      },
      hasEquipment:false,
      selectedTagContainer:null,
      companyOptions:[],
      locationOptions:[],
      companyContactOptions:[],
      supplierContactOptions: [],
      supplierOptions:[],
      projectSupplier: null,
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  /**
   * Validates my projectData keys + the selectedTagContainer variable.
   * We go through each entry of the customLists array and check,
   * if the customList is required. If it is, we add the customListId
   * into the LeadTags and set is as required.
   */
  validations(){
    const validations = {
      projectData: {
        Title:{required},
        Responsible: {required},
        Company: {required},
        Phase: {required},
      },
      selectedTagContainer:{required},
    };
    // Dynamically define validation rules for each item in customLists
    this.customLists.forEach(customList => {
      validations.projectData.ProjectTags = validations.projectData.ProjectTags || {};
      if(!customList.isOptional){
        validations.projectData.ProjectTags[customList.Id] = { required };
      }
    });

    return validations
  },

  computed:{
    responsibleOptions(){
      return this.$store.getters.responsibles.map(responsible => ({Name:responsible.FriendlyName,Id:responsible.User, type: "User"}));
    },

    contactOptions(){
      let members = [];
      members = [
        ...this.companyContactOptions,

        ...this.supplierContactOptions,
      ];

      return members.sort((a,b) => {
        if (a['Name'] < b['Name']) return -1;
        if (a['Name'] > b['Name']) return 1;
      });
    },

    /**
     * We iterate through the contacts and responsibles and map them into our members array with only
     * keys that we need for our members. After that we sort the array.
     * @returns {({Id: *, type: string, Name: *}|{Id: *, type: string, Name: *})[]}
     */
    membersOptions(){
      let members = [];
      members = [
        ...this.contactOptions
            .map(contact => ({ Name: contact.Name, Id: contact.Id, type: "Contact" })),

        ...this.$store.getters.responsibles
            .map(user => ({ Name: user.FriendlyName, Id: user.User, type: "User" })),
      ];

      return members.sort((a,b) => {
        if (a['Name'] < b['Name']) return -1;
        if (a['Name'] > b['Name']) return 1;
      });
    },

    /**
     * First we map all equipments to only contain the Name & Id keys.
     * If our projectData has a Company selected, we filter through all equipments and only return equipments,
     * that match the selected company, otherwise we return all equipments.
     * @returns {{Id: *, Name: *}[]}
     */
    equipmentOptions(){
      const equipments = this.$store.getters.equipments;
      let changedEquipmentData = equipments.map(equipment => ({Name: equipment.EquipmentName, Id: equipment.Id}));

      if (this.projectData.Company) {
        changedEquipmentData = equipments.filter(equipment => equipment.CompanyId === this.projectData.Company.Id)
        .map(equipment => ({Name: equipment.EquipmentName, Id: equipment.Id}));
      }
      return changedEquipmentData;
    },

    phaseOptions(){
      return this.$store.getters.projectPhases;
    },

    /**
     * We only return tagContainers where the Coverage equals Projects.
     */
    tagContainers(){
      return this.$store.getters.tagContainers.filter(tagContainer => tagContainer.Coverage === "Projects");
    },

    customLists(){
      const filteredCustomList = [];
      const customLists = this.$store.getters.customLists;
      if(this.selectedTagContainer){
        for(let customList of customLists){
          for(let tag of this.selectedTagContainer.Tags){
            if(tag === customList.Id){
              filteredCustomList.push(customList);
            }
          }
        }
      }
      return filteredCustomList;
    },
  },

  methods:{
    getCustomListLabel(customList){
      return this.$i18n.locale === 'de' ?
        customList.ClusterName + this.optionalText(customList.isOptional) :
        customList.ClusterNameEn + this.optionalText(customList.isOptional)
    },

    optionalText(isOptional){
      return isOptional ? ' (' + this.$t('optional') + ')' : ''
    },
    
    searchForCompanies(event){
      this.$store.commit('setLoading',false);

      clearTimeout(this.searchForCompanies.timeoutId);

      if (event.value.length > 2) {
        this.searchForCompanies.timeoutId = setTimeout(() => {
          this.$store.commit('setLoading',true);
          this.companyOptions = [];
          try{
            this.$store.dispatch("getRequest","getCompaniesBySearchValue&query=" + event.value).then(resp => {
              this.$store.commit('setLoading',false);
              if(resp && resp.statusCode === 200){
                this.companyOptions = JSON.parse(resp.body);
              }
            });
          }catch(err){
            console.log(err);
            this.$store.commit('setLoading', false);
          }
        }, 1000);
      }
    },

    setProjectCompany(event){
      const company = {Name:event.Name, Id:event.Id, CompanyNumber:event.CompanyNumber, City:event.City};
      this.projectData.Company = company;
      const foundCompanyIndex = this.companyOptions.findIndex(filteredCompany => filteredCompany.Id === company.Id);
      if(foundCompanyIndex !== -1){
        this.companyOptions[foundCompanyIndex] = company;
      }else{
        this.companyOptions.push(company);
      }
      this.getContactsByCompanyId(event.Id);
      this.locationOptions = event.GoodsReceivers ? event.GoodsReceivers : [];
      this.projectData.Equipment = [];
      this.projectData.Location = {};
      this.projectData.Contact = {Id:"",Name:"",Email:"",Phone:"",Landline:""};
    },

    getContactsByCompanyId(companyId){
      this.$store.commit('setLoading',true);
      try{
        this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + companyId).then(resp => {
          this.$store.commit('setLoading',false);
          if(resp && resp.statusCode === 200){
            const body = JSON.parse(resp.body);
            this.companyContactOptions = body.map(contact => ({
              Name: contact.ContactName + ' (' + contact.CompanyName + ')',
              Id:contact.ContactId,
              Email:contact.ContactEmail,
              Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
              Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : "",
            }));
          }
        });
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading', false);
      }
    },

    getContactsBySupplierId(supplierId){
      this.$store.commit('setLoading',true);
      try{
        if(this.projectData.Company.Id !== supplierId){
          this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + supplierId).then(resp => {
            this.$store.commit('setLoading',false);
            if(resp && resp.statusCode === 200){
              const body = JSON.parse(resp.body);
              this.supplierContactOptions = body.map(contact => ({
                Name: contact.ContactName + ' (' + contact.CompanyName + ')',
                Id:contact.ContactId,
                Email:contact.ContactEmail,
                Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
                Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : "",
              }));
            }
          });
        }
        this.$store.commit('setLoading',false);
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading', false);
      }
    },

    getCompanyOptionLabel(props){
      return props.Name + " '" + props.City + "' (" + props.CompanyNumber + ")";
    },

    setProjectSupplier(event) {
      this.projectData.Supplier = event.Name;
      this.projectData.SupplierId = event.Id;
      this.getContactsBySupplierId(event.Id);
    },

    setContact(event){
      this.projectData.Contact = event;
      const foundContactInMembers = this.projectData.Members.find(member => member.Id === event.Id);
      if(!foundContactInMembers){
        this.projectData.Members.push({Id:event.Id,Name:event.Name,type:"Contact"});
      }
    },

    /**
     * If all necessary keys are filled, we set the Created key.
     * Then, we check if the creator is inside the Members key.
     * If we have a selectedTagContainer we set the ProjectTagContainer key &
     * if we have a selectedCustomList, we set the ProjectTags key.
     * In the end we fire the addProject dispatch, emit to close the popup and show a confirmation toast.
     */
    addProject(){
      this.v$.$validate();
      if(!this.v$.$error) {
        this.projectData.Created = new Date().getTime();

        this.projectData.ProjectTagContainer = this.selectedTagContainer;

        this.customLists.forEach(customList => {
          const foundTag = Object.keys(this.projectData.ProjectTags).filter(tag => tag === customList.Id)[0];
          if(customList.isOptional && !foundTag){
            this.projectData.ProjectTags[customList.Id] = "";
          }
        });

        if(this.projectData.Contact && this.projectData.Contact.Id){
          this.projectData.ContactId = this.projectData.Contact.Id;
        }
        this.projectData.CompanyId = this.projectData.Company.Id;
        this.projectData.ResponsibleId = this.projectData.Responsible.Id;

        this.$toast.add({severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.projectCreated'), life: 3000});
        
        this.$store.dispatch('addProject', this.projectData).then(() => {
          this.$router.push({
            path:"/projects/dashboard/" + this.projectData.Id,
            params:this.projectData.Id
          });
          this.$emit('close-popup');
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== "" && !errorList.includes(this.$t('tags'))){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "Title":
            errorList = errorList + ' ' + this.$t('title');
            break;
          case "Responsible":
            errorList = errorList + ' ' + this.$t('responsible');
            break;
          case "Company":
            errorList = errorList + ' ' + this.$t('company')
            break;
          case "Phase":
            errorList = errorList + ' ' + this.$t('placeholder.phase');
            break;
          case "Description":
            errorList = errorList + ' ' + this.$t('description');
            break;
          case "selectedTagContainer":
            errorList = errorList + ' ' + this.$t('classification');
            break;
          default:
            errorList = errorList + ' ' + this.$t('tags');
            break;
        }
      }

      return errorList;
    },

    getSupplierOptions(){
      this.$store.commit('setLoading', true);
      try{
        this.$store.dispatch("getRequest","getSuppliers").then(resp => {
          this.$store.commit('setLoading', false);
          const body = JSON.parse(resp.body);
          this.supplierOptions = body.Items.map((item) => ({Name:item.Name, Id:item.Id}));

          this.supplierOptions.sort((a, b) => {
            if (a['Name'] < b['Name']) return -1;
            if (a['Name'] > b['Name']) return 1;
          });
        });
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading', false);
      }
    },
  },
  mounted(){
    this.getSupplierOptions();
  }
}
</script>

<style scoped>

</style>