<template>
<div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          scrollable
          :scrollHeight="windowHeight"
          dataKey="Id"
          stateStorage="local"
          stateKey="offerQueryTable"
          removableSort
          selectionMode="single"
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          @rowSelect="goToProjectDashboard">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <PrimeButton style="height:36px;min-width:36px"
                label="Excel"
                icon="pi pi-file-excel"
                class="mr-2"
                outlined
                severity="secondary"
                @click="exportToExcel" />

              <IconField
                iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filter['global'].value"
                  :placeholder="$t('placeholder.search')"
                  :style="isDesktop ? '' : 'width:200px;'" />
              </IconField>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="true"
            :sortable="col.field !== 'DeliveryDate'"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="getToolTip(data,field)">
                {{data[field] }}
              </span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PrimeButton from "primevue/button";
import InputText from "primevue/inputtext";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import * as XLSX from 'xlsx';

export default {
  name: "OfferQueryTable",
  mixins:[QueryBuilderFunctions, globalComputedProperties],
  data() {
    return {
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Company', header: this.$t('company') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Probability', header: this.$t('probability') },
        { field: 'Status', header: this.$t('status') },
        { field: 'SaleValue', header: this.$t('budget') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'BonusOption', header: this.$t('bonusOption') },
        { field: 'OfferNumber', header: this.$t('offerNumber') },
        { field: 'OrderNumber', header: this.$t('orderNumber') },
        { field: 'Supplier', header: this.$t('supplier') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Company:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        DeliveryDate:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Probability:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Status:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        SaleValue:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OfferValue:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        BonusOption:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OfferNumber:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OrderNumber:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Supplier:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
      },
      globalFilter:['Title', 'Company', 'DeliveryDate', 'Probability', 'Status', 'SaleValue', 'OfferValue', 'BonusOption', 'OfferNumber', 'OrderNumber', 'Supplier'],
      windowHeight:'',
    };
  },

  components: {
    DataTable, Column, InputText, PrimeButton, IconField, InputIcon,
  },

  computed:{
    gridDataSource(){
      return this.prepareDataSource();
    },
    
    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },
  },

  methods: {
    prepareDataSource(){
      let src = [];
      let offers = this.$store.getters.offers;

      for (let offer of offers){
        let obj = {};
        obj["Title"] = offer["Title"];
        obj["Company"] = offer["Company"].Name;
        obj["DeliveryDate"] = new Date(Number(offer["DeliveryDate"])).toLocaleDateString();
        obj["Probability"] = offer["Probability"];
        obj["Status"] = this.getOfferStatus(offer["Status"]);
        obj["SaleValue"] = offer["SaleValue"];
        obj["OfferValue"] = offer["OfferValue"];
        obj["BonusOption"] = offer['BonusOption'] ? offer["BonusOption"] : '';
        obj["OfferNumber"] = offer["OfferNumber"];
        obj["OrderNumber"] = offer["OrderNumber"];
        obj["Supplier"] = offer["Supplier"];
        obj["ProjectId"] = offer["ProjectId"];

        src.push(obj);
      }
      return src;
    },

    getOfferStatus(offerStatus){
      switch (offerStatus){
        case '10':
          return this.$t('open');
        case '20':
          return this.$t('sold');
        case '30':
          return this.$t('rejected');
        case '40':
          return this.$t('replaced');
        case '50':
          return this.$t('lost');
        case '60':
          return this.$t('delayed');
      }
    },

    getToolTip(data,field){
      return data[field];
    },

    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        const filteredRow = {};
        this.columns.forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        return filteredRow;
      });

      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      const headers = this.columns.map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'angebote_workbook.xlsx');
    },
       
    goToProjectDashboard(args){
      const projectId = args.data.ProjectId;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    }
  },

  created(){
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  }
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-multiselect-label){
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  padding: 8px 21px !important;
}
</style>