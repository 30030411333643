<template>
  <div>
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          dataKey="Id"
          stateStorage="local"
          stateKey="contactQueryTable"
          removableSort
          scrollable
          :scrollHeight="windowHeight"
          selectionMode="single"
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          @rowSelect="goToContactDashboard">
          <template #header>
            <div class="flex justify-content-between">
              <div class="flex align-items-center gap-2 mr-2">
                <span v-if="lastEvaluatedKey"
                  style="max-width:500px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis"
                >{{$t('dataLoadedTill')}} {{ lastEvaluatedKey.ContactName }}</span>
              </div>
              <div class="flex xl:justify-content-end align-items-center">
                <div v-if="shouldContinue" class="flex align-items-center gap-2">
                  <PrimeButton style="height:36px;"
                    class="mr-2"
                    size="small"
                    severity="danger"
                    @click="cancelGetData">
                    {{$t('cancel')}}
                  </PrimeButton>
                </div>
                <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                  <PrimeButton style="height:36px;"
                    class="mr-2"
                    outlined
                    size="small"
                    severity="secondary"
                    @click="getMoreContacts">
                    {{$t('loadMoreData')}}
                  </PrimeButton>
                </div>
                <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                  <PrimeButton style="height:36px;"
                    class="mr-2"
                    outlined
                    size="small"
                    severity="secondary"
                    @click="loadAllData">
                    {{$t('loadAllData')}}
                  </PrimeButton>
                </div>

                <PrimeButton style="height:36px;min-width:36px"
                  label="Excel"
                  icon="pi pi-file-excel"
                  class="mr-2"
                  outlined
                  severity="secondary"
                  @click="exportToExcel" />
                <IconField
                  iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText v-model="filter['global'].value"
                    :placeholder="$t('placeholder.search')"
                    :style="isDesktop ? '' : 'width:200px;'" />
                </IconField>
              </div>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="true"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
          <Column v-for="(col,index) of contactTagColumns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :showFilterMatchModes="true"
            :columnKey="col.field"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode, FilterOperator } from "primevue/api";
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import * as XLSX from 'xlsx';

export default {
  name: "PersonQueryTable",
  mixins:[QueryBuilderFunctions, globalComputedProperties],
  data() {
    return {
      columns:[
        { field: 'FriendlyName', header: this.$t('contact') },
        { field: 'FirstName', header: this.$t('firstName') },
        { field: 'LastName', header: this.$t('lastName') },
        { field: 'CompanyName', header: this.$t('company') },
        { field: 'Email', header: this.$t('email') },
        { field: 'Department', header: this.$t('department') },
        { field: 'Position', header: this.$t('position') },
        { field: 'Landline', header: this.$t('landline') },
        { field: 'Mobile', header: this.$t('mobile') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FriendlyName:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        FirstName:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        LastName:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        CompanyName:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Email:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Department:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Position:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Landline:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Mobile:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
      },
      globalFilter:['FriendlyName', 'FirstName', 'LastName', 'CompanyName', 'Email', 'Department', 'Position', 'Landline', 'Mobile'],
      contactTagColumns: [],
      windowHeight:'',
    };
  },
  components: {
    DataTable, Column, InputText, PrimeButton, IconField, InputIcon,
  },
  computed:{
    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.contactQueryData);
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },

    lastEvaluatedKey(){
      return this.$store.getters.contactLastEvaluatedKey;
    },

    shouldContinue(){
      return this.$store.getters.shouldContinue;
    }
  },
  methods: {
    prepareDataSource(contacts){
      const src = []
      for(let contact of contacts){
        let obj = {};
        obj["FriendlyName"] = contact["ContactName"];
        obj["FirstName"] = this.getValFromObject(contact.OptionalParams, "FirstName");
        obj["LastName"] = this.getValFromObject(contact.OptionalParams, "LastName");
        obj["CompanyName"] = contact["CompanyName"];
        obj["Email"] = contact["ContactEmail"];

        obj["Department"] = this.getValFromObject(contact.OptionalParams, "Department");
        obj["Position"] = this.getValFromObject(contact.OptionalParams, "Position");
        obj["Landline"] = this.getValFromObject(contact.OptionalParams, "Landline");
        obj["Mobile"] = this.getValFromObject(contact.OptionalParams, "Mobile");

        if(contact.Tags && contact.Tags.length > 0){
          for(let tag of contact.Tags){
            obj[tag.labelName] = tag.selectedValue.toString();
          }
        }

        obj["Id"] = contact["ContactId"];

        src.push(obj);
      }

      return src;
    },

    goToContactDashboard(args){
      const contactId = args.data.Id;
      this.$router.push({
        path:"/persons/contactDashboard/" + contactId,
        params:contactId
      });
    },

    initializeContactTagFilter() {
      this.contactTagColumns.forEach((col) => {
        this.filter[col.field] = {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]};  // Direct assignment to filter object
        this.globalFilter.push(col.header);
      });
    },

    createTagsColumns(){
      const contactTags = [];
      const tags = this.$store.getters.allCustomLists;
      const contactContainers = this.$store.getters.allTagContainers.filter((container) => container.Coverage === 'Contacts');
      for(let container of contactContainers){
        for(let tag of container.Tags){
          const foundContactTag = tags.find((currentTag) => currentTag.Id === tag);
          if(foundContactTag){
            const tagAlreadyExists = contactTags.some(tag => tag.header === foundContactTag.ClusterName);
            if(!tagAlreadyExists){
              const clusterName = foundContactTag.ClusterName;
              contactTags.push({
                header: clusterName,
                field: clusterName
              });
            }
          }
        }
      }

      return contactTags;
    },

    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        const filteredRow = {};
        this.columns.forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        this.contactTagColumns.forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        return filteredRow;
      });

      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      let headers = this.columns.map(col => col.header);
      headers = [...headers, ...this.contactTagColumns.map(col => col.header)];
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'kontakte_workbook.xlsx');
    },

    getContactsData(){
      if(this.gridDataSource.length === 0){
        this.$store.commit('setLoading', true);
        try{
          this.$store.dispatch('getRequest',"getAdminContacts").then((data)=>{
            if(data && data.statusCode && data.statusCode === 200){
              const body = JSON.parse(data.body);
              this.$store.commit('setContactLastEvaluatedKey', body["LastEvaluatedKey"]);
              this.$store.commit('setContactQueryData', body['Items']);
            }
            this.$store.commit('setLoading', false);
          });
        }catch(err){
          console.log(err);
          this.$store.commit('setLoading', false);
        }
      }
    },

    getMoreContacts(){
      this.$store.commit('setLoading', true);
      try{
        this.$store.dispatch('getRequest',"getMoreAdminContacts&query=" + [this.lastEvaluatedKey["ContactId"], this.lastEvaluatedKey["ContactName"]?.replace(',','#')]).then((data)=>{
          if(data && data.statusCode && data.statusCode === 200){
            const body = JSON.parse(data.body);
            this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
            this.$store.commit('setContactLastEvaluatedKey', body["LastEvaluatedKey"]);
            this.$store.commit('setContactQueryData', body['Items']);
          }
          this.$store.commit('setLoading', false);
        });
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading', false);
      }
    },

    loadAllData(){
      this.$store.commit('setShouldContinue', true);
      this.getAllContacts();
    },

    // Not sure why but it works if we directly check the store
    // the computed property doesnt update correctly in the async function
    async getAllContacts(lastKey = null){
      if (!this.$store.getters.shouldContinue) {
        return;
      }

      try {
        const queryParams = lastKey
          ? [lastKey.ContactId, lastKey.ContactName?.replace(',', '#')]
          : [this.lastEvaluatedKey?.ContactId, this.lastEvaluatedKey?.ContactName?.replace(',', '#')];

        const data = await this.$store.dispatch('getRequest', 'getMoreAdminContacts&query=' + queryParams);

        if (data) {
          const body = JSON.parse(data.body);
          this.$store.commit('setContactLastEvaluatedKey', body["LastEvaluatedKey"]);
          this.$store.commit('setContactQueryData', body['Items']);

          if (body['LastEvaluatedKey'] && this.$store.getters.shouldContinue) {
            await this.getAllContacts(body['LastEvaluatedKey']);
          } else {
            this.$store.commit('setShouldContinue', false);
          }
        }
      } catch (error) {
        this.$store.commit('setShouldContinue', false);
      }
    },

    cancelGetData() {
      this.$store.commit('setShouldContinue', false);
    }
  },

  created(){
    this.getContactsData();
    this.contactTagColumns = this.createTagsColumns();
    this.initializeContactTagFilter();
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  }
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-multiselect-label){
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-datatable .p-datatable-tbody > tr > td){
  padding: 8px 21px !important;
}
</style>