<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          scrollable
          :scrollHeight="windowHeight"
          dataKey="Id"
          stateStorage="local"
          stateKey="companyQueryTable"
          removableSort
          selectionMode="single"
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          @rowSelect="goToCompanyDashboard">
          <template #header>
            <div class="flex justify-content-between">
              <div class="flex align-items-center gap-2 mr-2">
                <span v-if="lastEvaluatedKey"
                  style="max-width:500px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis"
                >{{$t('dataLoadedTill')}} {{ lastEvaluatedKey.NameLowerCase }}</span>
              </div>
              <div class="flex xl:justify-content-end align-items-center">
                <div v-if="shouldContinue" class="flex align-items-center gap-2">
                  <PrimeButton style="height:36px;"
                    class="mr-2"
                    size="small"
                    severity="danger"
                    @click="cancelGetData">
                    {{$t('cancel')}}
                  </PrimeButton>
                </div>
                <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                  <PrimeButton
                    style="height:36px;"
                    class="mr-2"
                    outlined
                    size="small"
                    severity="secondary"
                    @click="getMoreCompanies">
                    {{$t('loadMoreData')}}
                  </PrimeButton>
                </div>
                <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                  <PrimeButton style="height:36px;"
                    class="mr-2"
                    outlined
                    size="small"
                    severity="secondary"
                    @click="loadAllData">
                    {{$t('loadAllData')}}
                  </PrimeButton>
                </div>

                <PrimeButton style="height:36px;min-width:36px"
                            label="Excel"
                            icon="pi pi-file-excel"
                            class="mr-2"
                            outlined
                            severity="secondary"
                            @click="exportToExcel" />

                <IconField
                  iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText v-model="filter['global'].value"
                    :placeholder="$t('placeholder.search')"
                    :style="isDesktop ? '' : 'width:200px;'" />
                </IconField>
              </div>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="true"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
          <Column v-for="(col,index) of companyTagColumns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :showFilterMatchModes="true"
            :columnKey="col.field"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import * as XLSX from 'xlsx';

export default {
  name: "CompanyQueryTable",
  mixins:[globalComputedProperties],
  data() {
    return {
      columns:[
        { field: 'Name', header: this.$t('name') },
        { field: 'City', header: this.$t('city') },
        { field: 'PostalCode', header: this.$t('postalCode') },
        { field: 'Address', header: this.$t('address') },
        { field: 'Phone', header: this.$t('phone') },
        { field: 'Responsible', header: this.$t('responsible') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        City:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        PostalCode:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Address:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Phone:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Responsible:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
      },
      globalFilter: ['Name', 'City', 'PostalCode', 'Address', 'Phone', 'Responsible'],
      companyTagColumns: [],
      windowHeight:'',
    };
  },
  components: {
    DataTable, Column, InputText, PrimeButton, IconField, InputIcon,
  },
  computed:{
    toolbarOptions(){
      const options = ['ExcelExport'];

      if(this.lastEvaluatedKey){
        options.push(
          {text: this.$t('loadMoreData'),
          tooltipText: 'Load more data',
          prefixIcon: 'e-refresh',
          id: 'toolbar_load',
          align: 'Right' });
      }
      return options;
    },

    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.companyQueryData);
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },

    lastEvaluatedKey(){
      return this.$store.getters.companyLastEvaluatedKey;
    },

    shouldContinue(){
      return this.$store.getters.shouldContinue;
    }
  },
  methods: {
    prepareDataSource(companies){
      const src = [];
      for(let company of companies){
        let obj = {};
        obj["Name"] = company["Name"];
        obj["City"] = company["City"];
        obj["PostalCode"] = company["PostalCode"];
        obj["Address"] = company["Address"];
        obj["Phone"] = company["Phone"];
        obj["Responsible"] = company["Responsible"];
        if(company.Tags && company.Tags.length > 0){
          for(let tag of company.Tags){
            obj[tag.labelName] = tag.selectedValue.toString();
          }
        }

        obj["Id"] = company["Id"];
        
        src.push(obj);
      }
      return src;
    },

    goToCompanyDashboard(args){
      const companyId = args.data.Id;
      this.$router.push({
        path:"/companies/dashboard/" + companyId,
        params:companyId
      });
    },

    initializeCompanyTagFilter() {
      this.companyTagColumns.forEach((col) => {
        this.filter[col.field] = {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]};  // Direct assignment to filter object
        this.globalFilter.push(col.header);
      });
    },

    createTagsColumns(){
      const companyTags = [];
      const tags = this.$store.getters.allCustomLists;
      const companyContainers = this.$store.getters.allTagContainers.filter((container) => container.Coverage === 'Companies');
      for(let container of companyContainers){
        for(let tag of container.Tags){
          const foundCompanyTag = tags.find((currentTag) => currentTag.Id === tag);
          if(foundCompanyTag){
            const tagAlreadyExists = companyTags.some(tag => tag.header === foundCompanyTag.ClusterName);
            if(!tagAlreadyExists){
              companyTags.push({
                header: foundCompanyTag.ClusterName,
                field:foundCompanyTag.ClusterName
              });
            }
          }
        }
      }
      return companyTags;
    },

    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        const filteredRow = {};
        this.columns.forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        this.companyTagColumns.forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        return filteredRow;
      });

      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      let headers = this.columns.map(col => col.header);
      headers = [...headers, ...this.companyTagColumns.map(col => col.header)];
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'firmen_workbook.xlsx');
    },

    // Here we get the equipment data from the server and return it
    getCompaniesData(){
      if(this.gridDataSource.length === 0){
        this.$store.commit('setLoading',true);
        this.hasFetchedData = true;
        try{
          this.$store.dispatch('getRequest',"getAdminCompanies").then((data)=>{
          if(data && data.statusCode && data.statusCode === 200){
            const body = JSON.parse(data.body);
            this.$store.commit('setCompanyLastEvaluatedKey', body["LastEvaluatedKey"]);
            this.$store.commit('setCompanyQueryData', body.Items);
          }
          this.$store.commit('setLoading',false);
        });
        } catch(err){
          console.log(err);
          this.$store.commit('setLoading',false);
        }
      }
    },

    getMoreCompanies(){
      try{
        this.$store.commit('setLoading',true);
        this.$store.dispatch('getRequest',"getMoreAdminCompanies&query=" + [this.lastEvaluatedKey["Id"], this.lastEvaluatedKey['NameLowerCase']?.replace(',','#')]).then((data)=>{
          if(data && data.statusCode && data.statusCode === 200){
          const body = JSON.parse(data.body);
          this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
          this.$store.commit('setCompanyLastEvaluatedKey', body["LastEvaluatedKey"]);
          this.$store.commit('setCompanyQueryData', body['Items']);
        }
        this.$store.commit('setLoading',false);
      });
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading',false);
      }
    },

    loadAllData(){
      this.$store.commit('setShouldContinue', true);
      this.getAllCompanies();
    },

    // Not sure why but it works if we directly check the store
    // the computed property doesnt update correctly in the async function
    async getAllCompanies(lastKey = null){
      if (!this.$store.getters.shouldContinue) {
        return;
      }

      try {
        const queryParams = lastKey
          ? [lastKey.Id, lastKey.NameLowerCase?.replace(',', '#')]
          : [this.lastEvaluatedKey?.Id, this.lastEvaluatedKey?.NameLowerCase?.replace(',', '#')];

        const data = await this.$store.dispatch('getRequest', 'getMoreAdminCompanies&query=' + queryParams);
        if (data) {
          const body = JSON.parse(data.body);
          this.$store.commit('setCompanyLastEvaluatedKey', body["LastEvaluatedKey"]);
          this.$store.commit('setCompanyQueryData', body['Items']);

          if (body['LastEvaluatedKey'] && this.$store.getters.shouldContinue) {
            await this.getAllCompanies(body['LastEvaluatedKey']);
          } else {
            this.$store.commit('setShouldContinue', false);
          }
        }
      } catch (error) {
        this.$store.commit('setShouldContinue', false);
      }
    },
    
    cancelGetData() {
      this.$store.commit('setShouldContinue', false);
    }
  },

  created(){
    this.getCompaniesData();
    this.companyTagColumns = this.createTagsColumns();
    this.initializeCompanyTagFilter();
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  },
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-multiselect-label){
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  padding: 8px 21px !important;
}
</style>