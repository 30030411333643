<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          scrollable
          :scrollHeight="windowHeight"
          dataKey="Id"
          stateStorage="local"
          stateKey="salesQueryTable"
          removableSort
          selectionMode="single"
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          @rowSelect="goToProjectDashboard">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <div v-if="shouldContinue" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                  class="mr-2"
                  size="small"
                  severity="danger"
                  @click="cancelGetData">
                  {{$t('cancel')}}
                </PrimeButton>
              </div>
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                  class="mr-2"
                  outlined
                  size="small"
                  severity="secondary"
                  @click="getMoreSales">
                  {{$t('loadMoreData')}}
                </PrimeButton>
              </div>
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                  class="mr-2"
                  size="small"
                  severity="secondary"
                  @click="loadAllData">
                  {{$t('loadAllData')}}
                </PrimeButton>
              </div>

              <PrimeButton style="height:36px;min-width:36px"
                label="Excel"
                icon="pi pi-file-excel"
                class="mr-2"
                outlined
                severity="secondary"
                @click="exportToExcel" />
              <IconField
                iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filter['global'].value"
                  :placeholder="$t('placeholder.search')"
                  :style="isDesktop ? '' : 'width:200px;'" />
              </IconField>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="true"
            :sortable="col.field !== 'SalesDate' && col.field !== 'DeliveryDate'"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="getToolTip(data,field)">
                {{field === 'CommissionValue' || field === 'OrderValue' || field === 'OfferValue' || field === 'AmountPaid' || field === 'MissingAmount' ? 
                  formatNumber(data[field])
                  : data[field]}}
              </span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import * as XLSX from 'xlsx';

export default {
  name: "SalesQueryTable",
  mixins:[QueryBuilderFunctions, globalComputedProperties],
  components:{
    DataTable, Column, InputText, PrimeButton, IconField, InputIcon,
  },
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'CommissionNumber', header: this.$t('commissionNumber') },
        { field: 'CommissionValue', header: this.$t('commissionValue') },
        { field: 'Company', header: this.$t('company') },
        { field: 'Contact', header: this.$t('contact') },
        { field: 'Responsible', header: this.$t('responsible') },
        { field: 'OrderNumber', header: this.$t('orderNumber') },
        { field: 'OrderValue', header: this.$t('orderValue') },
        { field: 'ReferenceNumber', header: this.$t('referenceNumber') },
        { field: 'SalesDate', header: this.$t('salesDate') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Equipment', header: this.$t('equipments') },
        { field: 'OfferName', header: this.$t('offer') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'Supplier', header: this.$t('supplier') },
        { field: 'AmountPaid', header: this.$t('amountPaid') },
        { field: 'MissingAmount', header: this.$t('missingAmount') },
        { field: 'PaymentDate', header: this.$t('paymentDate') },
        { field: 'PaymentDeadline', header: this.$t('paymentDeadline') },
        { field: 'CompetitionModel', header: this.$t('competitionModel') },
        { field: 'MachineModel', header: this.$t('machineModel') },
        { field: 'MachineNumber', header: this.$t('machineNumber') },
        { field: 'Discount', header: this.$t('discountPercentage') },
        { field: 'Notes', header: this.$t('notes') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        CommissionNumber:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        CommissionValue:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Company:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Contact:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Responsible:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OrderNumber:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OrderValue:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ReferenceNumber:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        SalesDate:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        DeliveryDate:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Equipment:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OfferName:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        OfferValue:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Supplier:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        AmountPaid:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        MissingAmount:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        PaymentDate:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        PaymentDeadline:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        CompetitionModel:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        MachineModel:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        MachineNumber:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Discount:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Notes:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
      },
      globalFilter:['Title', 'CommissionNumber', 'CommissionValue', 'Company', 'Contact',
       'Responsible', 'OrderNumber', 'OrderValue', 'ReferenceNumber', 'SalesDate', 'DeliveryDate', 'Equipment',
       'OfferName', 'OfferValue', 'Supplier', 'AmountPaid', 'MissingAmount', 'PaymentDate', 'PaymentDeadline',
       'CompetitionModel', 'MachineModel', 'MachineNumber', 'Discount', 'Notes'],
      windowHeight: '',
    }
  },
 
  computed:{
    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.sales);
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },

    lastEvaluatedKey(){
      return this.$store.getters.salesLastEvaluatedKey;
    },

    shouldContinue(){
      return this.$store.getters.shouldContinue;
    }
  },

  methods:{
    prepareDataSource(sales){
      let src = [];
      for (let sale of sales){
        let obj = {};
        obj["Title"] = sale["Title"];
        obj["CommissionNumber"] = sale["CommissionNumber"];
        obj["CommissionValue"] = sale["CommissionValue"];
        obj["Company"] = this.getValFromObject(sale["Company"], 'Name');
        obj["Contact"] = this.getValFromObject(sale.Contact, "Name");
        obj["Responsible"] = this.getValFromObject(sale.Responsible, "Name");
        obj["OrderNumber"] = sale["OrderNumber"];
        obj["OrderValue"] = sale["OrderValue"];
        obj["ReferenceNumber"] = sale["ReferenceNumber"];
        obj["SalesDate"] = new Date(Number(sale.SalesDate)).toLocaleDateString();
        obj["DeliveryDate"] = new Date(Number(sale.DeliveryDate)).toLocaleDateString();
        obj["Equipment"] =  this.getEquipmentNames(sale.Equipment);
        obj['OfferName'] = sale['OfferName'];
        obj["OfferValue"] = sale["OfferValue"];
        obj['Supplier'] = Array.isArray(sale['Supplier']) ? '-' : sale['Supplier'];
        obj['AmountPaid'] = sale.Payments && sale.Payments.length > 0 ? this.getTotalAmountPaid(sale.Payments) : '-';
        obj['MissingAmount'] = sale.Payments && sale.Payments.length > 0 ? sale.Payments[sale.Payments.length -1]['MissingAmount'] : '-';
        obj['PaymentDate'] = sale.Payments && sale.Payments.length > 0 ? new Date(Number(sale.Payments[sale.Payments.length -1]['PaymentDateUnix'])).toLocaleDateString() : '-';
        obj['PaymentDeadline'] = sale.Payments && sale.Payments.length > 0 ? new Date(Number(sale.Payments[sale.Payments.length -1]['PaymentDeadlineUnix'])).toLocaleDateString() : '-';
        obj['CompetitionModel'] = sale['CompetitionModel'];
        obj['MachineModel'] = sale['MachineModel'];
        obj['MachineNumber'] = sale['MachineNumber'];
        obj['Discount'] = sale['Discount'];
        obj['Notes'] = sale['Notes'];

        obj['ProjectId'] = sale['ProjectId'];

        src.push(obj);
      }

      return src;
    },

    getEquipmentNames(equipments){
      const equipmentNames = [];
      for(let equipment of equipments){
        equipmentNames.push(equipment.Name);
      }

      return equipmentNames.toString();
    },

    getTotalAmountPaid(payments){
      let totalPaid = 0;
      for(const payment of payments){
        totalPaid += Number(payment.AmountPaid);
      }

      return totalPaid.toFixed(2).toString();
    },

    getToolTip(data,field){
      switch(field){
        case 'CommissionValue':
        case 'OrderValue':
        case 'OfferValue':
        case 'AmountPaid':
        case 'MissingAmount':
          return this.formatNumber(data[field]);
        default:
        return data[field];
      }
      
    },

    /**
     * Formats the value of numbers to add a dot,
     * after every 3rd integer, i.e. 1.000, 45.918.347 .
     * @param value
     * @returns {string}
     */
     formatNumber(value) {
      if (value) {
        // Convert value to a string and split into integer and decimal parts
        let parts = value.toString().split(".");

        // Format the integer part with a period as the thousands separator
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        // Join the integer and decimal parts back together, if decimal part exists
        return parts.join(",");
      }
      return value;
    },  
        
    goToProjectDashboard(args){
      const projectId = args.data.ProjectId;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    },

    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        const filteredRow = {};
        this.columns.forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        return filteredRow;
      });

      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      let headers = this.columns.map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'verkäufe_workbook.xlsx');
    },

    getMoreSales(){
      if(this.lastEvaluatedKey && this.lastEvaluatedKey.SalesDate && this.lastEvaluatedKey.Id){
        const id = Number(this.lastEvaluatedKey["Id"]);
        const salesDate = Number(this.lastEvaluatedKey["SalesDate"]);
        this.$store.commit('setLoading', true);
        try{
          this.$store.dispatch("getRequest","getMoreSales&query=" +  [id, salesDate]).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
              this.$store.commit('setSales', body.Items);
              this.prepareDataSource(body.Items);

              if (body.LastEvaluatedKey && body.Items.length > 0) {
                this.$store.commit('setSalesLastEvaluatedKey', body.LastEvaluatedKey);
              }else{
                this.$store.commit('setSalesLastEvaluatedKey', null);
              }
            }
            this.$store.commit('setLoading', false);
          });
        }catch(err){
          console.log(err);
          this.$store.commit('setLoading', false);
        }
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
      }
    },
    
    loadAllData(){
      this.$store.commit('setShouldContinue', true);
      this.getAllSales();
    },

    // Not sure why but it works if we directly check the store
    // the computed property doesnt update correctly in the async function
    async getAllSales(lastKey = null){
      if (!this.$store.getters.shouldContinue) {
        return;
      }

      try {
        const queryKey = lastKey 
          ? [lastKey["Id"], lastKey["SalesDate"]] 
          : [this.lastEvaluatedKey?.Id, this.lastEvaluatedKey?.SalesDate];
        const data = await this.$store.dispatch('getRequest', "getMoreSales&query=" + queryKey);

        if (data) {
          const body = JSON.parse(data.body);
          this.$store.commit('setSalesLastEvaluatedKey', body["LastEvaluatedKey"]);
          this.$store.commit('setSales', body['Items']);
          this.prepareDataSource(body.Items);

          if (body['LastEvaluatedKey'] && this.$store.getters.shouldContinue) {
            await this.getAllSales(body['LastEvaluatedKey']);
          } else {
            this.$store.commit('setShouldContinue', false);
          }
        }
      } catch (error) {
        this.$store.commit('setShouldContinue', false);
      }
    },

    cancelGetData() {
      this.$store.commit('setShouldContinue', false);
    }
  },
  created(){
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  }
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-multiselect-label){
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-datatable .p-datatable-tbody > tr > td){
  padding: 8px 21px !important;
}
</style>